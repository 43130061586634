import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { Children } from "react";
import { Section } from "../section";
export const Hero = ({ children, backgroundImage, className }) => {
    /* A temporary solution to conditionally add classes for a container to display images properly."
      TODO: Replace the JavaScript below with CSS, when the :has selector will be available for Firefox.
    */
    const hasHeroImage = Children.count(children) > 1 &&
        Array.isArray(children) &&
        children.some(({ props }) => props.image && props.variant !== "full");
    return (_jsx(Section, { image: backgroundImage, className: classNames(className, "overflow-hidden [&>.aero-ui-container--default]:py-20", hasHeroImage &&
            "[&>.aero-ui-container--default]:relative [&>.aero-ui-container--default]:pb-0 lg:[&>.aero-ui-container--default]:py-20"), children: children }));
};
