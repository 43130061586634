import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
import { useId } from "react";
import PlayStoreIcon from "../../icons/play-store.svg";
import { LinkExternal } from "../link-external/link-external";
export const GOOGLE_PLAY_APP_URL = "https://play.google.com/store/apps/details?id=com.atombank.release&hl=en_GB";
export const GooglePlayButton = ({ className }) => {
    const id = useId();
    return (_jsx(LinkExternal, { href: GOOGLE_PLAY_APP_URL, "aria-label": "Play store download", className: classnames(className, "block text-black"), children: _jsx(PlayStoreIcon, { idPrefix: id }) }));
};
