var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import NukaCarousel from "nuka-carousel";
import { Fragment } from "react";
import { useBreakpointMatch } from "../../hooks";
import { CarouselControl } from "./carousel-control";
export const Carousel = (_a) => {
    var { children, slides = 3, mobileSlides = 1, spacing = 0, withoutControls } = _a, rest = __rest(_a, ["children", "slides", "mobileSlides", "spacing", "withoutControls"]);
    const isMd = useBreakpointMatch({ name: "md", triggerOnInit: true });
    const isSm = useBreakpointMatch({ name: "sm", triggerOnInit: true });
    return (_jsxs(Fragment, { children: [_jsx("noscript", { children: _jsx("style", { dangerouslySetInnerHTML: {
                        __html: `
              .slider-control-centerright, .slider-control-centerleft {
                display:none;
              }
              .slider-container {
                padding:0 !important;
              }
              `,
                    } }) }), _jsx("div", { className: classNames(!withoutControls && "sm:[&_.slider-container]:px-20"), children: _jsx(NukaCarousel, Object.assign({ slidesToShow: isMd ? slides : mobileSlides, cellSpacing: spacing, enableKeyboardControls: true, wrapAround: true }, (!isSm || withoutControls
                    ? { renderCenterLeftControls: null, renderCenterRightControls: null }
                    : {
                        renderCenterLeftControls: ({ previousSlide }) => (_jsx(CarouselControl, { direction: "left", onClick: previousSlide })),
                        renderCenterRightControls: ({ nextSlide }) => (_jsx(CarouselControl, { onClick: nextSlide })),
                    }), rest, { children: children })) })] }));
};
