var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
import { variantClassNames, variants } from "./types";
import { AppDownloadCard } from "./variants/app-download-card";
import { DownloadPreviewCard } from "./variants/download-preview-card";
import { PagePreviewNoImageCard } from "./variants/page-preview-no-image-card";
import { PagePreviewPrimaryCard } from "./variants/page-preview-primary-card";
import { PagePreviewSecondaryCard } from "./variants/page-preview-secondary-card";
import { PagePreviewTertiaryCard } from "./variants/page-preview-tertiary-card";
const variantComponents = {
    [variants.pagePreviewPrimary]: PagePreviewPrimaryCard,
    [variants.pagePreviewSecondary]: PagePreviewSecondaryCard,
    [variants.pagePreviewTertiary]: PagePreviewTertiaryCard,
    [variants.pagePreviewNoImage]: PagePreviewNoImageCard,
    [variants.downloadPreview]: DownloadPreviewCard,
    [variants.appDownload]: AppDownloadCard,
};
export const Card = (_a) => {
    var { children, className, bgImage } = _a, props = __rest(_a, ["children", "className", "bgImage"]);
    const classNames = classnames("card", "group", className, variantClassNames[props.variant], bgImage && "bg-cover");
    switch (props.variant) {
        case variants.pagePreviewPrimary:
            const PagePreviewPrimaryVariantComponent = variantComponents[variants.pagePreviewPrimary];
            return (_jsx("li", { className: classNames, children: _jsx(PagePreviewPrimaryVariantComponent, Object.assign({}, props, { children: children })) }));
        case variants.pagePreviewSecondary:
            const PagePreviewSecondaryVariantComponent = variantComponents[variants.pagePreviewSecondary];
            return (_jsx("li", { className: classNames, children: _jsx(PagePreviewSecondaryVariantComponent, Object.assign({}, props, { children: children })) }));
        case variants.pagePreviewTertiary:
            const PagePreviewTertiaryVariantComponent = variantComponents[variants.pagePreviewTertiary];
            return (_jsx("li", { className: classNames, children: _jsx(PagePreviewTertiaryVariantComponent, Object.assign({}, props, { children: children })) }));
        case variants.pagePreviewNoImage:
            const PagePreviewNoImageVariantComponent = variantComponents[variants.pagePreviewNoImage];
            return (_jsx("li", { className: classNames, children: _jsx(PagePreviewNoImageVariantComponent, Object.assign({}, props, { children: children })) }));
        case variants.downloadPreview:
            const DownloadPreviewVariantComponent = variantComponents[variants.downloadPreview];
            return (_jsx("li", { className: classNames, children: _jsx(DownloadPreviewVariantComponent, Object.assign({}, props, { children: children })) }));
        case variants.appDownload:
            const AppDownloadVariantComponent = variantComponents[variants.appDownload];
            return (_jsx("div", { className: classNames, children: _jsx(AppDownloadVariantComponent, Object.assign({}, props, { children: children })) }));
        default:
            return (_jsx("div", { className: classNames, style: { backgroundImage: bgImage && `url(${bgImage})` }, children: children }));
    }
};
