import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion as ReactAccordion } from "react-accessible-accordion";
import { AccordionItem } from "./accordion-item";
const variants = {
    default: "accordion__item--default",
    white: "accordion__item--white",
    transparent: "accordion__item--transparent",
};
export const Accordion = ({ variant = "default", panelVariant = "default", preExpanded, items, title, children, onExpand, }) => {
    if (!title && !(items === null || items === void 0 ? void 0 : items.length)) {
        return null;
    }
    return (_jsxs(ReactAccordion, { allowMultipleExpanded: true, allowZeroExpanded: true, preExpanded: preExpanded, onChange: onExpand ? () => onExpand(title) : undefined, children: [items === null || items === void 0 ? void 0 : items.map((item, index) => (_jsx(AccordionItem, Object.assign({ className: variants[variant], panelVariant: panelVariant }, item, { index: index }), item.title))), children && (_jsx(AccordionItem, { title: title, className: variants[variant], panelVariant: panelVariant, index: 0, children: children }))] }));
};
