import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpointMatch } from "../../hooks";
import { Accordion } from "../accordion";
import { LinkInternal } from "../link-internal";
const DesktopWrapper = ({ children, title }) => (_jsxs(_Fragment, { children: [_jsx("p", { className: "my-2 text-white opacity-75", children: title }), _jsx("ol", { className: "list-none pl-0", children: children })] }));
const MobileWrapper = ({ children, title }) => (_jsx(Accordion, { title: title, variant: "transparent", children: _jsx("ol", { className: "list-none pl-2", children: children }) }));
export const FooterLinks = ({ title, links, linkComponent, }) => {
    const showMobile = useBreakpointMatch({
        name: "lg",
        triggerOnInit: true,
    });
    const WrapperComponent = showMobile ? DesktopWrapper : MobileWrapper;
    return (_jsx("div", { className: "footer__links", children: _jsx(WrapperComponent, { title: title, children: links.map(({ name, url }, index) => (_jsx("li", { className: "lg:text-para mb-6 text-lg", children: _jsx(LinkInternal, { linkComponent: linkComponent, className: "text-white no-underline hover:opacity-80", to: url, children: name }) }, index))) }) }));
};
