import { jsx as _jsx } from "react/jsx-runtime";
import AtomLogo from "../../icons/atom-logo.svg?url";
import DMLogo from "../../icons/dm-logo.svg?url";
import { LinkInternal } from "../link-internal";
const logos = {
    AB: {
        icon: AtomLogo,
        iconWidth: "w-[8rem]",
    },
    DM: {
        icon: DMLogo,
        iconWidth: "w-[13rem]",
    },
};
export const Logo = ({ site, linkComponent }) => (_jsx("div", { className: logos[site].iconWidth, children: _jsx(LinkInternal, { linkComponent: linkComponent, to: "/", className: "w-full", children: _jsx("img", { src: logos[site].icon, alt: "Go to the home page" }) }) }));
