import { jsx as _jsx } from "react/jsx-runtime";
import { isGatsbyEnv } from "../../utils/is-gatsby-env";
export const LinkInternal = ({ children, to, className, id, tabIndex, linkComponent: Component, }) => {
    const isGatsby = isGatsbyEnv();
    if (!Component && isGatsby) {
        throw Error("Please pass GatsbyLink under 'linkComponent' prop.");
    }
    if (Component && isGatsby) {
        return (_jsx(Component, { to: to, className: className, id: id, tabIndex: tabIndex, children: children }));
    }
    return (_jsx("a", { href: to, className: className, id: id, tabIndex: tabIndex, children: children }));
};
