import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { LinkExternal } from "../link-external";
import { LinkInternal } from "../link-internal";
export const NavDropdownList = ({ links, expanded, subMenuName, variant = "site-nav", external = true, linkComponent, }) => {
    const minWidth = variant === "site-nav" ? "min-w-[14.5em]" : "";
    const padding = variant === "cross-site-nav" ? "px-6" : "pl-4 pr-0";
    return (_jsx("ul", { className: classNames(!expanded && `hidden`, expanded && `block`, `absolute left-0 top-[calc(100%)] my-0 w-full list-none rounded-bl rounded-br bg-white px-6 text-atom-aero-secondary-purple shadow-z1Light`, minWidth, padding), "aria-hidden": !expanded, "aria-label": `${subMenuName} submenu`, children: links.map(({ name, url, selected }) => {
            const styles = classNames(selected ? "underline" : "no-underline", "text-atom-aero-secondary-purple font-normal");
            const label = `Link to ${name} in ${subMenuName} submenu`;
            return (_jsx("li", { className: "my-4 last-of-type:mt-0", children: external ? (_jsx(LinkExternal, { href: url, className: styles, "aria-label": label, children: name })) : (_jsx(LinkInternal, { linkComponent: linkComponent, to: url, className: styles, "aria-label": label, children: name })) }, name));
        }) }));
};
