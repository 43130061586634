import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BiChevronDown } from "react-icons/bi";
import { LinkInternal } from "../link-internal/link-internal";
import { NavDropdownList } from "./nav-dropdown-list";
export const CrossSiteNavAb = ({ submenuExpanded, triggerProps, expandButtonProps, linkComponent, }) => (_jsx(_Fragment, { children: _jsxs("ul", { className: "my-0 flex", children: [_jsx("li", { className: "cross-site-nav-item mr-[1em] bg-white", children: _jsx(LinkInternal, { linkComponent: linkComponent, to: "/", className: "block font-normal text-atom-aero-secondary-purple no-underline", children: "Personal & Business" }) }), _jsxs("li", Object.assign({}, triggerProps, { className: "cross-site-nav-item group bg-atom-aero-primary-purple font-normal focus-within:bg-white focus-within:text-atom-aero-secondary-purple hover:bg-white hover:text-atom-aero-secondary-purple", children: [_jsxs("button", Object.assign({}, expandButtonProps, { className: "unstyled-button block", "aria-expanded": submenuExpanded, "aria-haspopup": "true", "aria-label": "show Intermediaries submenu", children: ["Intermediaries", _jsx(BiChevronDown, { className: "nav-chevron-down ml-2" })] })), _jsx(NavDropdownList, { linkComponent: linkComponent, expanded: submenuExpanded, links: [
                            {
                                name: "Residential",
                                url: "https://www.digitalmortgages.net/residential/",
                            },
                            {
                                name: "Business",
                                url: "https://www.digitalmortgages.net/business/",
                            },
                        ], subMenuName: "Intermediaries", variant: "cross-site-nav", external: true })] }))] }) }));
