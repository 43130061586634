import { useEffect } from "react";
const hide = () => {
    document.body.style.overflow = "hidden";
};
const show = () => {
    document.body.style.overflow = "";
};
const toggle = () => (document.body.style.overflow === "hidden" ? show() : hide());
export const useBodyOverflow = ({ dependsOn }) => {
    useEffect(() => {
        if (dependsOn) {
            hide();
        }
        else {
            show();
        }
    }, [dependsOn]);
    return { hide, show, toggle };
};
