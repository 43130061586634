import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import defaultTailwindTheme from "tailwindcss/defaultTheme";
import { useOnRouteChange } from "../../hooks/use-on-route-change";
import { useResizeMatchMediaListener } from "../../hooks/use-resize-match-media-listener";
import { useSuperNavContext } from "../../hooks/use-super-nav-context";
import { CrossSiteNav } from "./cross-site-nav";
import { SiteNav } from "./site-nav";
export const SuperNav = ({ site, siteLinks, dmSubSite, linkComponent }) => {
    const { isMobileMenuVisible, hideMobileMenu } = useSuperNavContext();
    useResizeMatchMediaListener({
        mediaQuery: `(max-width: ${defaultTailwindTheme.screens.lg + 1})`,
        onNotMatch: hideMobileMenu,
    });
    useOnRouteChange(() => isMobileMenuVisible && hideMobileMenu());
    return (_jsxs(_Fragment, { children: [_jsx(CrossSiteNav, { linkComponent: linkComponent, dmSubSite: dmSubSite }), _jsx(SiteNav, { linkComponent: linkComponent, site: site, siteLinks: siteLinks })] }));
};
