/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";

import theme from "./theme";

export const SiteContext = createContext();

const Context = ({ children }) => {
  const [config, setConfig] = useState(false);
  const [environment] = useState(process.env.NODE_ENV);
  const [location] = useState(typeof window !== "undefined" && window.location);

  useEffect(() => {
    setConfig({
      statusBanner: { visible: false },
      trustPilot: { stars: 5, validFrom: "9th July 2024" },
    });
  }, []);

  return (
    <SiteContext.Provider
      value={{
        config,
        environment,
        location,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const ThemeWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Context>{children}</Context>
  </ThemeProvider>
);

export default ThemeWrapper;
