exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-a-better-world-js": () => import("./../../../src/pages/about-us/a-better-world.js" /* webpackChunkName: "component---src-pages-about-us-a-better-world-js" */),
  "component---src-pages-about-us-carbon-report-2023-js": () => import("./../../../src/pages/about-us/carbon-report-2023.js" /* webpackChunkName: "component---src-pages-about-us-carbon-report-2023-js" */),
  "component---src-pages-about-us-carbon-report-js": () => import("./../../../src/pages/about-us/carbon-report.js" /* webpackChunkName: "component---src-pages-about-us-carbon-report-js" */),
  "component---src-pages-about-us-fintech-pledge-js": () => import("./../../../src/pages/about-us/fintech-pledge.js" /* webpackChunkName: "component---src-pages-about-us-fintech-pledge-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-our-customers-js": () => import("./../../../src/pages/about-us/our-customers.js" /* webpackChunkName: "component---src-pages-about-us-our-customers-js" */),
  "component---src-pages-about-us-our-customers-specialist-support-js": () => import("./../../../src/pages/about-us/our-customers/specialist-support.js" /* webpackChunkName: "component---src-pages-about-us-our-customers-specialist-support-js" */),
  "component---src-pages-about-us-our-people-js": () => import("./../../../src/pages/about-us/our-people.js" /* webpackChunkName: "component---src-pages-about-us-our-people-js" */),
  "component---src-pages-about-us-technology-js": () => import("./../../../src/pages/about-us/technology.js" /* webpackChunkName: "component---src-pages-about-us-technology-js" */),
  "component---src-pages-accountancy-data-js": () => import("./../../../src/pages/accountancy-data.js" /* webpackChunkName: "component---src-pages-accountancy-data-js" */),
  "component---src-pages-business-kitchen-js": () => import("./../../../src/pages/business-kitchen.js" /* webpackChunkName: "component---src-pages-business-kitchen-js" */),
  "component---src-pages-business-loans-business-roadmap-js": () => import("./../../../src/pages/business-loans/business-roadmap.js" /* webpackChunkName: "component---src-pages-business-loans-business-roadmap-js" */),
  "component---src-pages-business-loans-commercial-mortgages-js": () => import("./../../../src/pages/business-loans/commercial-mortgages.js" /* webpackChunkName: "component---src-pages-business-loans-commercial-mortgages-js" */),
  "component---src-pages-business-loans-growth-guarantee-scheme-js": () => import("./../../../src/pages/business-loans/growth-guarantee-scheme.js" /* webpackChunkName: "component---src-pages-business-loans-growth-guarantee-scheme-js" */),
  "component---src-pages-business-loans-js": () => import("./../../../src/pages/business-loans.js" /* webpackChunkName: "component---src-pages-business-loans-js" */),
  "component---src-pages-careers-csa-js": () => import("./../../../src/pages/careers/csa.js" /* webpackChunkName: "component---src-pages-careers-csa-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cbils-js": () => import("./../../../src/pages/cbils.js" /* webpackChunkName: "component---src-pages-cbils-js" */),
  "component---src-pages-complaints-data-js": () => import("./../../../src/pages/complaints-data.js" /* webpackChunkName: "component---src-pages-complaints-data-js" */),
  "component---src-pages-consumer-duty-js": () => import("./../../../src/pages/consumer-duty.js" /* webpackChunkName: "component---src-pages-consumer-duty-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-risk-assessment-js": () => import("./../../../src/pages/covid-19-risk-assessment.js" /* webpackChunkName: "component---src-pages-covid-19-risk-assessment-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-fscs-js": () => import("./../../../src/pages/fscs.js" /* webpackChunkName: "component---src-pages-fscs-js" */),
  "component---src-pages-gender-pay-gap-js": () => import("./../../../src/pages/gender-pay-gap.js" /* webpackChunkName: "component---src-pages-gender-pay-gap-js" */),
  "component---src-pages-help-faqs-app-app-js": () => import("./../../../src/pages/help/faqs-app/app.js" /* webpackChunkName: "component---src-pages-help-faqs-app-app-js" */),
  "component---src-pages-help-faqs-app-confirmation-of-payee-js": () => import("./../../../src/pages/help/faqs-app/confirmation-of-payee.js" /* webpackChunkName: "component---src-pages-help-faqs-app-confirmation-of-payee-js" */),
  "component---src-pages-help-faqs-app-fixed-saver-js": () => import("./../../../src/pages/help/faqs-app/fixed-saver.js" /* webpackChunkName: "component---src-pages-help-faqs-app-fixed-saver-js" */),
  "component---src-pages-help-faqs-app-help-js": () => import("./../../../src/pages/help/faqs-app/help.js" /* webpackChunkName: "component---src-pages-help-faqs-app-help-js" */),
  "component---src-pages-help-faqs-app-holding-account-js": () => import("./../../../src/pages/help/faqs-app/holding-account.js" /* webpackChunkName: "component---src-pages-help-faqs-app-holding-account-js" */),
  "component---src-pages-help-faqs-app-instant-saver-js": () => import("./../../../src/pages/help/faqs-app/instant-saver.js" /* webpackChunkName: "component---src-pages-help-faqs-app-instant-saver-js" */),
  "component---src-pages-help-faqs-app-js": () => import("./../../../src/pages/help/faqs-app.js" /* webpackChunkName: "component---src-pages-help-faqs-app-js" */),
  "component---src-pages-help-faqs-app-money-worries-js": () => import("./../../../src/pages/help/faqs-app/money-worries.js" /* webpackChunkName: "component---src-pages-help-faqs-app-money-worries-js" */),
  "component---src-pages-help-faqs-app-new-js": () => import("./../../../src/pages/help/faqs-app-new.js" /* webpackChunkName: "component---src-pages-help-faqs-app-new-js" */),
  "component---src-pages-help-faqs-js": () => import("./../../../src/pages/help/faqs.js" /* webpackChunkName: "component---src-pages-help-faqs-js" */),
  "component---src-pages-help-money-worries-js": () => import("./../../../src/pages/help/money-worries.js" /* webpackChunkName: "component---src-pages-help-money-worries-js" */),
  "component---src-pages-how-to-complain-js": () => import("./../../../src/pages/how-to-complain.js" /* webpackChunkName: "component---src-pages-how-to-complain-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-information-js": () => import("./../../../src/pages/investor-information.js" /* webpackChunkName: "component---src-pages-investor-information-js" */),
  "component---src-pages-media-assets-executive-committee-js": () => import("./../../../src/pages/media-assets/executive-committee.js" /* webpackChunkName: "component---src-pages-media-assets-executive-committee-js" */),
  "component---src-pages-media-assets-js": () => import("./../../../src/pages/media-assets.js" /* webpackChunkName: "component---src-pages-media-assets-js" */),
  "component---src-pages-media-assets-logos-and-guidelines-js": () => import("./../../../src/pages/media-assets/logos-and-guidelines.js" /* webpackChunkName: "component---src-pages-media-assets-logos-and-guidelines-js" */),
  "component---src-pages-media-assets-team-atom-and-our-hq-js": () => import("./../../../src/pages/media-assets/team-atom-and-our-hq.js" /* webpackChunkName: "component---src-pages-media-assets-team-atom-and-our-hq-js" */),
  "component---src-pages-modern-slavery-statement-js": () => import("./../../../src/pages/modern-slavery-statement.js" /* webpackChunkName: "component---src-pages-modern-slavery-statement-js" */),
  "component---src-pages-mortgages-first-time-buyers-js": () => import("./../../../src/pages/mortgages/first-time-buyers.js" /* webpackChunkName: "component---src-pages-mortgages-first-time-buyers-js" */),
  "component---src-pages-mortgages-homes-for-ukraine-scheme-js": () => import("./../../../src/pages/mortgages/homes-for-ukraine-scheme.js" /* webpackChunkName: "component---src-pages-mortgages-homes-for-ukraine-scheme-js" */),
  "component---src-pages-mortgages-js": () => import("./../../../src/pages/mortgages.js" /* webpackChunkName: "component---src-pages-mortgages-js" */),
  "component---src-pages-mortgages-less-than-perfect-credit-js": () => import("./../../../src/pages/mortgages/less-than-perfect-credit.js" /* webpackChunkName: "component---src-pages-mortgages-less-than-perfect-credit-js" */),
  "component---src-pages-mortgages-mortgage-knowledge-hub-js": () => import("./../../../src/pages/mortgages/mortgage-knowledge-hub.js" /* webpackChunkName: "component---src-pages-mortgages-mortgage-knowledge-hub-js" */),
  "component---src-pages-mortgages-moving-home-js": () => import("./../../../src/pages/mortgages/moving-home.js" /* webpackChunkName: "component---src-pages-mortgages-moving-home-js" */),
  "component---src-pages-mortgages-product-transfer-js": () => import("./../../../src/pages/mortgages/product-transfer.js" /* webpackChunkName: "component---src-pages-mortgages-product-transfer-js" */),
  "component---src-pages-mortgages-remortgage-js": () => import("./../../../src/pages/mortgages/remortgage.js" /* webpackChunkName: "component---src-pages-mortgages-remortgage-js" */),
  "component---src-pages-open-banking-js": () => import("./../../../src/pages/open-banking.js" /* webpackChunkName: "component---src-pages-open-banking-js" */),
  "component---src-pages-policies-cookies-js": () => import("./../../../src/pages/policies/cookies.js" /* webpackChunkName: "component---src-pages-policies-cookies-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-princes-trust-js": () => import("./../../../src/pages/princes-trust.js" /* webpackChunkName: "component---src-pages-princes-trust-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-savings-fixed-saver-js": () => import("./../../../src/pages/savings/fixed-saver.js" /* webpackChunkName: "component---src-pages-savings-fixed-saver-js" */),
  "component---src-pages-savings-instant-saver-js": () => import("./../../../src/pages/savings/instant-saver.js" /* webpackChunkName: "component---src-pages-savings-instant-saver-js" */),
  "component---src-pages-savings-js": () => import("./../../../src/pages/savings.js" /* webpackChunkName: "component---src-pages-savings-js" */),
  "component---src-pages-savings-savings-knowledge-hub-js": () => import("./../../../src/pages/savings/savings-knowledge-hub.js" /* webpackChunkName: "component---src-pages-savings-savings-knowledge-hub-js" */),
  "component---src-pages-secured-loans-price-list-js": () => import("./../../../src/pages/secured-loans/price-list.js" /* webpackChunkName: "component---src-pages-secured-loans-price-list-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-sign-up-success-js": () => import("./../../../src/pages/sign-up/success.js" /* webpackChunkName: "component---src-pages-sign-up-success-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tax-strategy-js": () => import("./../../../src/pages/tax-strategy.js" /* webpackChunkName: "component---src-pages-tax-strategy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-unsubscribe-successful-js": () => import("./../../../src/pages/unsubscribe-successful.js" /* webpackChunkName: "component---src-pages-unsubscribe-successful-js" */),
  "component---src-pages-unsubscribing-from-service-messages-js": () => import("./../../../src/pages/unsubscribing-from-service-messages.js" /* webpackChunkName: "component---src-pages-unsubscribing-from-service-messages-js" */),
  "component---src-pages-women-in-finance-js": () => import("./../../../src/pages/women-in-finance.js" /* webpackChunkName: "component---src-pages-women-in-finance-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-fixed-saver-product-js": () => import("./../../../src/templates/fixed-saver-product.js" /* webpackChunkName: "component---src-templates-fixed-saver-product-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-instant-saver-product-js": () => import("./../../../src/templates/instant-saver-product.js" /* webpackChunkName: "component---src-templates-instant-saver-product-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */),
  "component---src-templates-newsroom-js": () => import("./../../../src/templates/newsroom.js" /* webpackChunkName: "component---src-templates-newsroom-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */)
}

