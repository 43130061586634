const theme = {
  colors: {
    yellow: "#fdda24",
    green: "#47d7ac",
    pink: "#d42360",
    legacyPink: "#e0467b",
    pastel: "#cfc7dd",
    lightPastel: "#f5f4f8",
    lightPastelPink: "#faf9fc",
    translucentPastel: "rgba(206, 198, 220, 0.5)",
    purple: "#614b79",
    deepPurple: "#3c1053",
    blue: "#52d9e2",
    grey: "#4d4848",
    darkGrey: "#222222",
    lightGrey: "#aaaaaa",
    error: "#dd0000",
    focus: "#ffeb3b",
    lightBlue: "#53d9e2",
    black: "#4D4B4B",
  },
  dimensions: {
    viewportMax: "1400px",
    viewportMaxInteger: 1400,
    clip: "70px",
    mobile: `900px`,
    mobileInteger: 900,
  },
  fonts: {
    primary: `Museo Sans, sans-serif`,
  },
  gradients: {
    purpleToPink: `linear-gradient(170deg, #280838 0%, #41115a 30%, #e0467b 100%)`,
    purpleToGreen: `linear-gradient(170deg, rgb(40, 8, 56) 0%, rgb(65, 17, 90) 30%, rgb(71, 215, 172) 100%)`,
  },
  shadows: {
    z1: "0px 2px 4px #00000080",
    z2: "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3)",
    z3: "0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    z4: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2)",
    z5: "0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2)",
    z6: "0px 2px 2px #00000033",
  },
};

export default theme;
