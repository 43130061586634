import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BiChevronDown } from "react-icons/bi";
import { IntermediaryAtomMSOUrls, PortalAtomBankUrls, PublicSitesUrls, } from "../../const/sites-urls";
import { NavDropdownList } from "./nav-dropdown-list";
const urlsConfig = {
    residential: {
        register: IntermediaryAtomMSOUrls.Register,
        login: IntermediaryAtomMSOUrls.Login,
    },
    business: {
        register: PortalAtomBankUrls.Register,
        login: PortalAtomBankUrls.Login,
    },
};
export const CrossSiteNavDm = ({ dmSubSite, submenuExpanded, triggerProps, expandButtonProps, linkComponent, }) => {
    const config = urlsConfig[dmSubSite];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex", children: [_jsx("div", { className: "cross-site-nav-item mr-[1em] bg-atom-aero-primary-purple text-white focus-within:bg-white focus-within:text-atom-aero-secondary-purple hover:bg-white hover:text-atom-aero-secondary-purple", children: _jsx("a", { href: PublicSitesUrls.AtomBank, className: "font-normal no-underline", children: "Personal & Business" }) }), _jsxs("div", Object.assign({}, triggerProps, { className: "cross-site-nav-item group bg-white text-atom-aero-secondary-purple", children: [_jsxs("button", Object.assign({}, expandButtonProps, { className: "unstyled-button block", "aria-expanded": submenuExpanded, "aria-haspopup": "true", "aria-label": "show Intermediaries submenu", children: ["Intermediaries", _jsx(BiChevronDown, { className: "nav-chevron-down ml-2" })] })), _jsx(NavDropdownList, { linkComponent: linkComponent, expanded: submenuExpanded, links: [
                                    {
                                        name: "Residential",
                                        url: "/residential/",
                                        selected: dmSubSite === "residential",
                                    },
                                    {
                                        name: "Business",
                                        url: "/business/",
                                        selected: dmSubSite === "business",
                                    },
                                ], subMenuName: "Intermediaries", variant: "cross-site-nav" })] }))] }), _jsxs("div", { className: "cross-site-nav-item text-center", children: [dmSubSite.toUpperCase(), " - FOR INTERMEDIARIES ONLY"] }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "cross-site-nav-item", children: _jsx("a", { href: config.register, className: "block font-normal text-white no-underline", children: "Register" }) }), _jsx("div", { className: "cross-site-nav-item", children: _jsx("a", { href: config.login, className: "block font-normal text-white no-underline", children: "Login" }) })] })] }));
};
