export const PublicSitesUrls = {
    AtomBank: "https://www.atombank.co.uk/",
    DigitalMortgages: "https://www.digitalmortgages.net/",
    AtomBankPortal: "https://portal.atombank.co.uk/",
    IntermediaryAtomMSO: "https://intermediary.msoportal.atombank.co.uk/",
};
export const IntermediaryAtomMSOUrls = {
    Register: `${PublicSitesUrls.IntermediaryAtomMSO}registration/#/register`,
    Login: `${PublicSitesUrls.IntermediaryAtomMSO}`,
};
export const PortalAtomBankUrls = {
    Register: `${PublicSitesUrls.AtomBankPortal}customer_bbsl/sign-up/`,
    Login: `${PublicSitesUrls.AtomBankPortal}accounts/login/`,
};
