import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from "react";
export const SuperNavContext = createContext({
    toggleMobileMenu: () => { },
    showMobileMenu: () => { },
    hideMobileMenu: () => { },
    isMobileMenuVisible: false,
});
export const SuperNavContextProvider = ({ children }) => {
    const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
    return (_jsx(SuperNavContext.Provider, { value: {
            toggleMobileMenu: () => setMobileMenuVisible((value) => !value),
            showMobileMenu: () => setMobileMenuVisible(true),
            hideMobileMenu: () => setMobileMenuVisible(false),
            isMobileMenuVisible,
        }, children: children }));
};
