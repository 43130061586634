import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import { useId } from "react";
import { AccordionItem as ReactAccessibleAccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState, } from "react-accessible-accordion";
import ChevronDownCircleIcon from "../../icons/navigational/chevron-down-dark-circle.svg";
export const panelVariants = {
    default: "accordion__item__panel--default",
    custom: "accordion__item__panel--custom",
};
export const AccordionItem = ({ title, textBody, htmlBody, children, className, index, panelVariant = "default", }) => {
    const id = useId();
    return (_jsxs(ReactAccessibleAccordionItem, { className: classnames(className, "accordion__item"), uuid: index, children: [_jsx(AccordionItemHeading, { className: "accordion__item__heading", children: _jsxs(AccordionItemButton, { className: "accordion__item__heading__button", children: [title, _jsx(AccordionItemState, { children: ({ expanded }) => (_jsx(ChevronDownCircleIcon, { idPrefix: id, className: classnames("accordion__item__icon", expanded && "icon--flipped") })) })] }) }), _jsxs(AccordionItemPanel, { className: classnames("accordion__item__panel", panelVariants[panelVariant]), children: [textBody, htmlBody && _jsx("div", { dangerouslySetInnerHTML: { __html: htmlBody } }), children] })] }));
};
