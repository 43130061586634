import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
const variants = {
    primary: "badge--primary",
    secondary: "badge--secondary",
};
export const Badge = ({ children, variant = "primary", className }) => {
    const variantClassName = variants[variant];
    const classNames = classnames("badge", className, variantClassName);
    return _jsx("span", { className: classNames, children: children });
};
