var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export const Image = (_a) => {
    var { image, src, alt, className, objectFit, objectPosition, imageComponent: Component } = _a, rest = __rest(_a, ["image", "src", "alt", "className", "objectFit", "objectPosition", "imageComponent"]);
    if (image) {
        if (!Component) {
            throw Error("Please pass GatsbyImage under 'imageComponent' prop.");
        }
        return (_jsx(Component, Object.assign({ className: className, image: image, alt: alt, objectFit: objectFit, objectPosition: objectPosition }, rest)));
    }
    return _jsx("img", Object.assign({ className: className, src: src, alt: alt }, rest));
};
