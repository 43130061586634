import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Fragment } from "react";
import { Skeleton, SkeletonBlock } from "../skeleton";
export const FeefoBadge = ({ type = "default", isLoading, placeholderClassName, }) => {
    const getReevooelement = () => {
        switch (type) {
            case "mortgages":
                return (_jsx("reevoo-badge", { type: "product", trkref: "ATB_MTG", "product-id": "series:mtg", variant: "mortgages" }));
            case "fixed-saver":
                return (_jsx("reevoo-badge", { type: "product", trkref: "ATB", "product-id": "series:fts", variant: "fixed_savers" }));
            case "instant-saver":
                return (_jsx("reevoo-badge", { type: "product", trkref: "ATB", "product-id": "ABIA1", variant: "fixed_savers" }));
            default:
                return (_jsx("reevoo-badge", { type: "brand", variant: "cx_badge_small", trkref: "ATB", "reevoo-click-action": "open_lightbox" }));
        }
    };
    return (_jsxs(Fragment, { children: [_jsx("div", { "data-widget": type, className: classNames("table-row", isLoading && "hidden"), children: getReevooelement() }), isLoading && (_jsx("div", { "data-placeholder": type, className: classNames(placeholderClassName, "h-[74px] w-[214px] rounded border border-neutral-200"), children: _jsxs(Skeleton, { className: "flex h-full max-h-full flex-row items-center justify-between px-2 py-1", children: [_jsxs("div", { className: "flex h-full max-h-full w-1/4 flex-col items-start gap-1", children: [_jsx(SkeletonBlock, { className: "aspect-square h-3/4 w-auto" }), _jsx(SkeletonBlock, { className: "aspect-[4/1] h-[calc(25%-0.25rem)] w-auto" })] }), _jsxs("div", { className: "flex h-full flex-col items-end gap-2", children: [_jsx(SkeletonBlock, { className: "h-3/5 w-32" }), _jsx(SkeletonBlock, { className: "h-2/5 w-24" })] })] }) }))] }));
};
