import { useEffect } from "react";
export const useEventListener = (event, callback, selector) => {
    useEffect(() => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
            element.addEventListener(event, callback);
        });
        return () => {
            elements.forEach((element) => element.removeEventListener(event, callback));
        };
    }, [callback, event, selector]);
};
