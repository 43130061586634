import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useSubmenuHandler } from "../../hooks";
import { CrossSiteNavAb } from "./cross-site-nav-ab";
import { CrossSiteNavDm } from "./cross-site-nav-dm";
const crossSiteNavConfig = {
    AB: CrossSiteNavAb,
    DM: CrossSiteNavDm,
};
export const CrossSiteNav = ({ dmSubSite, linkComponent, }) => {
    // Determine the site based on the presence of dmSubSite
    const site = dmSubSite ? "DM" : "AB";
    const CrossSiteNavElement = crossSiteNavConfig[site];
    const { submenuExpanded, triggerProps, expandButtonProps } = useSubmenuHandler();
    return (_jsx("nav", { "aria-label": "Visit Other Atom Bank Sites", children: _jsx("div", { className: classNames(site === "AB" ? "lgAB:block" : "lg:block", "hidden w-full bg-atom-aero-primary-purple shadow-z1Light"), children: _jsx("div", { className: "mx-auto flex max-w-[1400px] justify-between text-white", children: _jsx(CrossSiteNavElement, { linkComponent: linkComponent, dmSubSite: dmSubSite, triggerProps: triggerProps, submenuExpanded: submenuExpanded, expandButtonProps: expandButtonProps }) }) }) }));
};
