var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import ArrowRightCircle from "../../icons/arrow-right-circle.svg";
export const CarouselControl = (_a) => {
    var { direction = "right" } = _a, rest = __rest(_a, ["direction"]);
    return (_jsx("button", Object.assign({ className: classNames("carousel__control rounded-full text-atom-aero-secondary-purple outline-offset-2", direction === "left" && "rotate-180"), "aria-label": `Scroll ${direction}` }, rest, { children: _jsx(ArrowRightCircle, {}) })));
};
