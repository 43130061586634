import { useEffect, useState } from "react";
export const useDetectAdBlock = ({ url }) => {
    const [adBlockDetected, setAdBlockDetected] = useState(false);
    useEffect(() => {
        fetch(url, {
            method: "HEAD",
            mode: "no-cors",
            cache: "no-store",
        })
            .then(({ redirected }) => {
            if (redirected)
                setAdBlockDetected(true);
        })
            .catch(() => {
            setAdBlockDetected(true);
        });
    }, [url]);
    return adBlockDetected;
};
