import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { arrow, autoUpdate, flip, FloatingArrow, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, } from "@floating-ui/react";
import { useRef, useState } from "react";
export const Tooltip = ({ className, children, placement, text }) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        placement,
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);
    return (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ ref: refs.setReference }, getReferenceProps(), { className: className, children: children })), isOpen && (_jsxs("div", Object.assign({ ref: refs.setFloating, style: floatingStyles, className: "tooltip" }, getFloatingProps(), { children: [text, _jsx(FloatingArrow, { ref: arrowRef, context: context, className: "tooltip__arrow" })] })))] }));
};
