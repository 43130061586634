var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { Image } from "../../image";
import { LinkInternal } from "../../link-internal";
import { H3 } from "../../typography";
export const PagePreviewTertiaryCard = (_a) => {
    var { children, path, imageClassName, title, backgroundImage, linkComponent } = _a, rest = __rest(_a, ["children", "path", "imageClassName", "title", "backgroundImage", "linkComponent"]);
    const hasImage = Boolean(rest.src || rest.image);
    const content = (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames("relative flex rounded-t-2xl bg-cover p-8 md:items-center", {
                    "md:h-52": hasImage,
                    "md:h-32 lg:h-40": !hasImage,
                }), style: {
                    backgroundImage: backgroundImage && `url(${backgroundImage})`,
                }, children: [_jsx(H3, { className: classNames("mb-0 text-white", {
                            "md:max-w-[46%]": hasImage,
                            "mb-52": hasImage,
                            "md:mb-0": hasImage,
                        }), children: title }), hasImage ? _jsx(Image, Object.assign({}, rest, { className: imageClassName })) : null] }), _jsx("div", { className: "grow px-8 py-9", children: children })] }));
    if (!path) {
        return content;
    }
    return (_jsx(LinkInternal, { linkComponent: linkComponent, to: path, className: "card__link", children: content }));
};
