var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DownloadArrow from "../../../icons/download-arrow.svg";
import { Image } from "../../image";
import { LinkExternal } from "../../link-external";
export const DownloadPreviewCard = (_a) => {
    var { path, title, date } = _a, rest = __rest(_a, ["path", "title", "date"]);
    return (_jsxs(LinkExternal, { href: path, className: "card__link", children: [_jsxs("div", { className: "relative", children: [_jsx(Image, Object.assign({}, rest, { className: "image--rounded aspect-5/3" })), _jsx("div", { className: "card__icon--primary absolute bottom-4 right-4 h-10 w-10 p-2.5", children: _jsx(DownloadArrow, { className: "h-10 w-10" }) })] }), _jsx("h3", { className: "card__heading--below-image text-atom-aero-gray", children: title }), _jsx("p", { className: "card__text", children: date })] }));
};
