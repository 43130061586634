import { useCallback } from "react";
import { useResizeListener } from "./use-resize-listener";
export const useResizeMatchMediaListener = ({ mediaQuery, triggerOnInit, onMatch, onNotMatch, }) => {
    const handler = useCallback(() => {
        const { matches } = window.matchMedia(mediaQuery);
        if (matches) {
            onMatch === null || onMatch === void 0 ? void 0 : onMatch();
        }
        else {
            onNotMatch === null || onNotMatch === void 0 ? void 0 : onNotMatch();
        }
    }, [mediaQuery, onMatch, onNotMatch]);
    useResizeListener(handler, triggerOnInit);
};
