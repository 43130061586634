import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch as HeadlessUICheckbox } from "@headlessui/react";
import classnames from "classnames";
import { select } from "radash";
import { useState } from "react";
import Tick from "../../icons/tick.svg";
// Shared functions/vars for checked classNames
const checkboxOptionContainerClass = "checkbox__option-container";
const checkboxOrientation = (inline) => classnames(inline ? "flex" : "block");
export const CheckboxGroup = ({ options, inline = false, onChange }) => {
    const [checkboxValues, setCheckboxValues] = useState(options);
    const handleCheckboxChange = (index) => {
        // Update the internal state of checked items
        setCheckboxValues([
            ...checkboxValues.slice(0, index),
            Object.assign(Object.assign({}, checkboxValues[index]), { checked: !checkboxValues[index].checked }),
            ...checkboxValues.slice(index + 1),
        ]);
        if (onChange !== undefined) {
            const selected = select(checkboxValues, (o) => o.value, (o) => o.checked === true);
            onChange(selected);
        }
    };
    return (_jsx(HeadlessUICheckbox.Group, { children: _jsx("div", { className: checkboxOrientation(inline), children: checkboxValues.map(({ name, label, checked }, index) => (_jsxs("div", { className: "mb-4 flex items-center gap-4", children: [_jsx(HeadlessUICheckbox, { name: name, checked: checked, className: checkboxOptionContainerClass, onChange: () => handleCheckboxChange(index), children: _jsx("span", { className: "checkbox__option", children: checked && _jsx(Tick, {}) }) }), _jsx(HeadlessUICheckbox.Label, { className: "mr-4", children: label })] }, label))) }) }));
};
/**
 * Implements a single checkbox
 *
 * @callback onChange
 * @param {string} label - displayed next to the checkbox
 * @param {string} name - Optional - Used with HTML forms - see https://headlessui.com/react/switch#using-with-html-forms
 *
 * @returns JSX
 */
export const Checkbox = ({ label, onChange, }) => {
    const [checked, setChecked] = useState(false);
    const handleOnChange = (value) => {
        onChange !== undefined && onChange(value);
        setChecked(value);
    };
    return (_jsx(HeadlessUICheckbox.Group, { children: _jsxs("div", { className: "flex items-center gap-4", children: [_jsx(HeadlessUICheckbox, { checked: checked, className: checkboxOptionContainerClass, onChange: handleOnChange, children: _jsx("span", { className: "checkbox__option", children: checked && _jsx(Tick, {}) }) }), _jsx(HeadlessUICheckbox.Label, { className: "mr-4", children: label })] }) }));
};
