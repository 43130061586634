import { globalHistory } from "@reach/router";
import { useEffect } from "react";
export const useOnRouteChange = (callback) => {
    useEffect(() => {
        return globalHistory.listen(({ action, location }) => {
            if (["PUSH", "POP"].includes(action)) {
                callback(location);
            }
        });
    }, [callback]);
};
