import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { LinkExternal } from "../link-external/link-external";
import { LinkInternal } from "../link-internal/link-internal";
import { NavMobileButton } from "./nav-mobile-button";
import { MobileMenuButtons } from "./site-nav";
export var DMMobileMenuSubmenu;
(function (DMMobileMenuSubmenu) {
    DMMobileMenuSubmenu["Residential"] = "Residential";
    DMMobileMenuSubmenu["Business"] = "Business";
})(DMMobileMenuSubmenu || (DMMobileMenuSubmenu = {}));
const siteLinks = [
    {
        name: DMMobileMenuSubmenu.Residential,
        url: "https://www.digitalmortgages.net/residential/",
    },
    {
        name: DMMobileMenuSubmenu.Business,
        url: "https://www.digitalmortgages.net/business/",
    },
];
export const NavMobileButtonsSection = ({ active, onTopButtonClick, site, linkComponent, }) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "z-10 flex min-h-[84px] overflow-hidden", children: [site === "AB" ? (_jsx(NavMobileButton, { active: active === MobileMenuButtons.AB, onClick: () => onTopButtonClick(MobileMenuButtons.AB), children: "Personal & Business" })) : (_jsx(LinkExternal, { className: classNames("flex min-w-[50%] cursor-pointer items-center justify-center border-none bg-atom-aero-primary-purple px-2 text-center text-[1.2em] text-white sm:px-0 sm:text-[1.5em]", active === MobileMenuButtons.AB
                        ? "bg-white text-atom-aero-secondary-purple"
                        : "bg-atom-aero-secondary-purple text-white"), href: "https://www.atombank.co.uk/", children: "Personal & Business" })), _jsx(NavMobileButton, { active: active === MobileMenuButtons.DM, onClick: () => onTopButtonClick(MobileMenuButtons.DM), "aria-haspopup": "true", "aria-expanded": active === MobileMenuButtons.DM, children: "Intermediaries" })] }), _jsx("ul", { "aria-hidden": active !== MobileMenuButtons.DM, className: classNames("m-0 flex justify-around bg-white text-atom-aero-secondary-purple", active === MobileMenuButtons.DM
                ? "linear min-h-[84px] transition-[min-height] duration-150"
                : "h-0 min-h-0"), children: active === MobileMenuButtons.DM &&
                siteLinks.map(({ name, url }) => (_jsx("li", { className: "flex", children: site === "DM" ? (_jsx(LinkInternal, { linkComponent: linkComponent, className: classNames(typeof window !== "undefined" &&
                            window.location.pathname.includes(new URL(url).pathname) &&
                            "bg-atom-aero-secondary-purple text-white", "m-4 flex min-w-[50%] cursor-pointer items-center justify-center rounded-xl px-5 py-3 text-[1.2em] font-normal no-underline active:bg-atom-aero-secondary-purple active:text-white sm:text-[1.5em]"), to: new URL(url).pathname, children: name })) : (_jsx(LinkExternal, { className: "m-4 flex min-w-[50%] cursor-pointer items-center justify-center rounded-xl px-5 py-3 text-[1.2em] font-normal no-underline active:bg-atom-aero-secondary-purple active:text-white sm:text-[1.5em]", href: url, children: name })) }, name))) })] }));
