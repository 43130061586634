import { useEffect } from "react";
export const useResizeListener = (callback, triggerOnInit = false) => {
    useEffect(() => {
        if (triggerOnInit) {
            callback();
        }
        window.addEventListener("resize", callback);
        return () => {
            window.removeEventListener("resize", callback);
        };
    }, [triggerOnInit, callback]);
};
