import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./static/~/global.css";

import { SuperNavContextProvider } from "@web/aero-ui/components";
import { anchorate } from "anchorate";

import Context from "./src/components/utils/context";

export const onRouteUpdate = ({ location }) => {
  anchorate();
  document.body.style.position = "";
  document.body.style.overflow = "";

  if (process.env.NODE_ENV === `production`) {
    // Facebook pixel functionality
    if (typeof fbq === `function`) {
      // eslint-disable-next-line no-undef
      fbq("track", "ViewContent");
    }

    // GTAG functionality
    if (typeof gtag === "function") {
      const sendPageView = () => {
        const pagePath = location ? location.pathname + location.search + location.hash : undefined;
        // eslint-disable-next-line no-undef
        gtag("event", "page_view", {
          page_path: pagePath,
        });
      };

      if ("requestAnimationFrame" in window) {
        requestAnimationFrame(() => {
          requestAnimationFrame(sendPageView);
        });
      } else {
        // simulate 2 rAF calls
        setTimeout(sendPageView, 32);
      }

      // eslint-disable-next-line unicorn/no-null
      return null;
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <SuperNavContextProvider>
    <Context>{element}</Context>
  </SuperNavContextProvider>
);
