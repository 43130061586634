import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import defaultTailwindTheme from "tailwindcss/defaultTheme";
import { useResizeMatchMediaListener } from "./use-resize-match-media-listener";
export const useBreakpointMatch = ({ name, triggerOnInit }) => {
    const pixels = defaultTailwindTheme.screens[name];
    const matchMediaQuery = `(min-width: ${pixels})`;
    const [match, setMatch] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setMatch(window.matchMedia(matchMediaQuery).matches);
        }
    }, [matchMediaQuery]);
    const { onMatch, onNotMatch } = useMemo(() => ({
        onMatch: debounce(() => setMatch(true)),
        onNotMatch: debounce(() => setMatch(false)),
    }), []);
    useResizeMatchMediaListener({
        triggerOnInit,
        mediaQuery: matchMediaQuery,
        onMatch,
        onNotMatch,
    });
    return match;
};
