import { jsx as _jsx } from "react/jsx-runtime";
import Facebook from "../../icons/social/facebook-with-circle.svg";
import Instagram from "../../icons/social/insta-with-circle.svg";
import LinkedIn from "../../icons/social/linkedin-with-circle.svg";
import Youtube from "../../icons/social/youtube-with-circle.svg";
import { ComponentAs } from "../component-as";
import { LinkExternal } from "../link-external";
const socialIcons = {
    facebook: _jsx(Facebook, { className: "icon icon--facebook" }),
    youtube: _jsx(Youtube, { className: "icon icon--youtube" }),
    instagram: _jsx(Instagram, { className: "icon icon--instagram" }),
    linkedin: _jsx(LinkedIn, { className: "icon icon--linkedin" }),
};
export const SocialLink = ({ link, as = "li" }) => {
    const { hostname } = new URL(link);
    const hostnameWithoutDomain = hostname.split(".")[0];
    return (_jsx(ComponentAs, { as: as, className: "mr-3 list-none pb-2 last:mr-0 sm:mr-6 sm:pb-0", children: _jsx(LinkExternal, { href: link, className: "social-link", "aria-label": `Link to ${hostname}`, children: socialIcons[hostnameWithoutDomain] }) }));
};
