import { useState } from "react";
export const useSubmenuHandler = () => {
    const [submenuExpanded, setSubmenuExpanded] = useState(false);
    const toggleExpand = () => setSubmenuExpanded((expand) => !expand);
    const onBlur = (event) => submenuExpanded &&
        !event.currentTarget.contains(event.relatedTarget) &&
        setSubmenuExpanded(false);
    const expandButtonProps = {
        onClick: toggleExpand,
    };
    const triggerProps = {
        onMouseEnter: (event) => {
            var _a;
            // exclusion below fixes double triggering menu on desktop size with touch events
            if ((event.target instanceof HTMLElement || event.target instanceof SVGElement) &&
                ((_a = event.target.closest("button")) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                return;
            }
            setSubmenuExpanded(true);
        },
        onMouseLeave: () => {
            setSubmenuExpanded(false);
        },
        onBlur,
    };
    return {
        triggerProps,
        submenuExpanded,
        expandButtonProps,
    };
};
