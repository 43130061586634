export const variants = {
    default: "default",
    pagePreviewPrimary: "pagePreviewPrimary",
    pagePreviewSecondary: "pagePreviewSecondary",
    pagePreviewTertiary: "pagePreviewTertiary",
    pagePreviewNoImage: "pagePreviewNoImage",
    downloadPreview: "downloadPreview",
    appDownload: "appDownload",
};
export const variantClassNames = {
    default: "card--default",
    pagePreviewPrimary: "card--page-preview-primary",
    pagePreviewSecondary: "card--page-preview-secondary",
    pagePreviewTertiary: "card--page-preview-tertiary",
    pagePreviewNoImage: "card--page-preview-no-image",
    downloadPreview: "card--download-preview",
    appDownload: "card--app-download",
};
