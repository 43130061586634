import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
const variantClassNames = {
    default: "aero-ui-container--default",
    thin: "aero-ui-container--thin",
    long: "aero-ui-container--long",
};
export const Section = ({ className, containerClassName, variant = "default", children, image, }) => {
    const bgImageClassName = image && "bg-cover";
    const sectionClassNames = classnames(className, bgImageClassName, "aero-ui-section");
    const containerClassNames = classnames("aero-ui-container", containerClassName, variantClassNames[variant]);
    return (_jsx("section", { style: {
            backgroundImage: image && `url(${image})`,
            backgroundPosition: "center",
        }, className: sectionClassNames, children: _jsx("div", { className: containerClassNames, children: children }) }));
};
