var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ChevronRight from "../../../icons/chevron-right.svg";
import { Image } from "../../image/image";
import { LinkInternal } from "../../link-internal";
export const PagePreviewSecondaryCard = (_a) => {
    var { path, title, children, linkComponent } = _a, rest = __rest(_a, ["path", "title", "children", "linkComponent"]);
    return (_jsxs(LinkInternal, { linkComponent: linkComponent, to: path, className: "card__link", children: [_jsxs("div", { className: "relative xlAB:basis-1/3", children: [_jsx(Image, Object.assign({}, rest, { className: "image--rounded aspect-5/3 h-auto w-full xlAB:aspect-auto xlAB:h-full xlAB:w-auto" })), _jsx("div", { className: "card__icon--secondary", children: _jsx(ChevronRight, { className: "h-10 w-10" }) })] }), _jsxs("div", { className: "basis-2/3 p-2", children: [_jsx("h3", { className: "card__heading--below-image sm:card__heading card__heading--hover text-atom-aero-gray", children: title }), _jsx("div", { className: "card__text card__text--hover", children: children })] })] }));
};
