var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "../../badge";
import { Image } from "../../image";
import { LinkInternal } from "../../link-internal";
import { Heading } from "../../typography";
export const PagePreviewPrimaryCard = (_a) => {
    var { path, tag, headingAs = "h3", title, date, linkComponent } = _a, rest = __rest(_a, ["path", "tag", "headingAs", "title", "date", "linkComponent"]);
    return (_jsxs(LinkInternal, { linkComponent: linkComponent, to: path, className: "card__link", children: [_jsxs("div", { className: "relative", children: [_jsx(Image, Object.assign({}, rest, { className: "image--rounded aspect-5/3" })), tag && _jsx(Badge, { className: "badge--deep-purple absolute bottom-4 left-4", children: tag })] }), _jsx(Heading, { as: headingAs, className: "mx-2 my-4 text-lg font-medium text-atom-aero-gray", children: title }), _jsx("p", { className: "card__text", children: date })] }));
};
