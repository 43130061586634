import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { useRef } from "react";
import { useSuperNavContext, useWindowEvent } from "../../hooks";
import CloseIcon from "../../icons/navigational/close.svg";
import HamburgerIcon from "../../icons/navigational/hamburger.svg";
export const NavMobileMenuButton = ({ hideClass, onClick }) => {
    const { toggleMobileMenu, hideMobileMenu, isMobileMenuVisible } = useSuperNavContext();
    const menuButtonReference = useRef();
    useWindowEvent("keydown", (event) => {
        var _a;
        if (event.key === "Escape" && isMobileMenuVisible) {
            hideMobileMenu();
            (_a = menuButtonReference.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    });
    return (_jsx("button", { className: classNames(hideClass, "nav-mobile-menu-button"), "aria-label": "Open menu ", "aria-haspopup": "true", "aria-expanded": isMobileMenuVisible, tabIndex: 0, onClick: () => {
            toggleMobileMenu();
            onClick();
        }, children: isMobileMenuVisible ? (_jsx(CloseIcon, { className: "w-20 text-[14px]" })) : (_jsx(HamburgerIcon, { ref: menuButtonReference, className: "w-20 text-[14px]" })) }));
};
