var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from "classnames";
import { ComponentAs } from "../component-as/component-as";
import { LinkExternal } from "../link-external/link-external";
import { LinkInternal } from "../link-internal/link-internal";
export var ButtonTypes;
(function (ButtonTypes) {
    ButtonTypes["Button"] = "button";
    ButtonTypes["LinkInternal"] = "link-internal";
    ButtonTypes["LinkExternal"] = "link-external";
})(ButtonTypes || (ButtonTypes = {}));
const variants = {
    primary: "button--primary",
    secondary: "button--secondary",
    tertiary: "button--tertiary",
    noStyles: "button--no-styles",
};
export const Button = (_a) => {
    var { type = ButtonTypes.Button, variant = "primary", children, className } = _a, props = __rest(_a, ["type", "variant", "children", "className"]);
    const linkClass = [ButtonTypes.LinkInternal, ButtonTypes.LinkExternal].includes(type)
        ? "button--link"
        : "";
    const classNames = classnames(variant !== "noStyles" && "button", variants[variant], linkClass, className);
    switch (type) {
        case ButtonTypes.LinkInternal:
            return (_jsx(LinkInternal, Object.assign({ className: classNames }, props, { children: children })));
        case ButtonTypes.LinkExternal:
            return (_jsx(LinkExternal, Object.assign({ className: classNames }, props, { children: children })));
    }
    return (_jsx(ComponentAs, Object.assign({ className: classNames }, props, { as: props.as || "button", children: children })));
};
