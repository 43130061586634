var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useBreakpointMatch } from "../../hooks";
import ChevronDown from "../../icons/chevron-down.svg";
import { Tab } from "./tab";
import { TabList } from "./tab-list";
import { TabPanel } from "./tab-panel";
import { Tabs } from "./tabs";
export const ResponsiveTabs = (_a) => {
    var _b;
    var { items } = _a, rest = __rest(_a, ["items"]);
    const showMobile = useBreakpointMatch({
        name: "sm",
        triggerOnInit: true,
    });
    const [selectedOption, setSelectedOption] = useState(items[0].title);
    const handleOptionChange = useCallback((event) => setSelectedOption(event.currentTarget.value), []);
    return (_jsxs("div", { className: "responsive-tabs", children: [_jsxs(Tabs, { "aria-hidden": showMobile, children: [_jsx(TabList, Object.assign({}, rest, { children: items.map(({ title }) => (_jsx(Tab, { id: title, children: title }, title))) })), items.map(({ title, content }) => (_jsx(TabPanel, { id: title, children: content }, title)))] }), _jsxs("div", { className: "responsive-tabs__mobile-container", "aria-hidden": !showMobile, children: [_jsxs("div", { className: "responsive-tabs__mobile-container__select-container", children: [_jsx("select", { "aria-label": rest["aria-label"], onChange: handleOptionChange, children: items.map(({ title }) => (_jsx("option", { "aria-labelledby": `tabs-content-${title}`, value: title, children: title }, title))) }), _jsx(ChevronDown, {})] }), _jsx("div", { className: "responsive-tabs__mobile-container__content", id: `tabs-content-${selectedOption}`, children: (_b = items.find(({ title }) => selectedOption === title)) === null || _b === void 0 ? void 0 : _b.content })] })] }));
};
